import React from "react";

import { colors, fontFamily } from "../../UIKit";
import { Text, Title, TitleBlock } from "../commonStyled";
import { subscriptionsData } from "./data";
import { Container, SubscriptionList } from "./styled";
import { SubscriptionItem } from "./SubscriptionItem";

export const Membership = () => {
  return (
    <Container id="subscription">
      <TitleBlock>
        <Text
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={121}
          $ls={2.4}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          subscription
        </Text>
        <Title $fs={24} $mt={12} $mb={24}>
          Choose your membership
        </Title>
      </TitleBlock>
      <SubscriptionList>
        {subscriptionsData.map((sub, index) => (
          <SubscriptionItem key={index} sub={sub} />
        ))}
      </SubscriptionList>
    </Container>
  );
};
