export const subscriptionsData = [
  {
    title: "Challenge",
    perMonth: "$19.99 / mo.",
    price: "$19.99",
    period: "monthly",
    eventValue: 19.99,
    eventItemId: "month",
    recommended: false,
    advantages: [
      {
        name: "A latte less, a glow more - see the quick effects of face yoga.",
      },
      { name: "Perfect for starters - try without a long-term commitment." },

      {
        name: "Experience the refreshment and feel the difference in 4 weeks.",
      },
    ],
  },
  {
    title: "Radiant Revolution",
    perMonth: "$7.50 / mo.",
    price: "$89.99",
    period: "ANUALLY",
    eventValue: 89.99,
    eventItemId: "annual",
    recommended: true,
    advantages: [
      {
        name: "Step away from invasive treatments - invest in long-lasting wellness.",
      },
      { name: "Full year access to all resources and exercises." },
      {
        name: "Make face yoga a part of your daily routine and enjoy enduring transformation. ",
      },
    ],
  },
  {
    title: "Glow & Go!",
    perMonth: "$16.60 / mo.",
    price: "$49.99",
    period: "quartely",
    eventValue: 49.99,
    eventItemId: "quartely",
    recommended: false,
    advantages: [
      {
        name: "Dive deeper into the world of face yoga and become a pro.",
      },
      { name: "Shift to a natural and sustainable skincare routine." },
      {
        name: "Witness the gradual transformation - a new glow each day. ",
      },
    ],
  },
];
